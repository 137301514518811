<script>
import { ApiClientSelectedPlatform } from "@/common/http-common"
import { BredcrumpManagerEncode, BredcrumpManagerDecode } from "@/common/bredcrumb-common"
import { showNotificationFromServer, showNotificationWithText } from "@/common/notification-common"
export default {
    data() {
        return {
            permissions: [],
            pagination: {
                path: "",
                total: 0,
                totalPages: 0,
                page: 1,
                pageSize: 10,
                nextPages: [],
                backPages: []
            }

        };
    },
    props: {
        type: {
            type: String,
            default: 'invoice',
        }
    },
    watch: {
        $route(to, from) {
            if (to.name == "invoice" && from.name == "platform/invoice") {
                window.location.reload(true);
            }
            if (to.name == "delivery" && from.name == "platform/delivery") {
                window.location.reload(true);
            }
               
        }
    },
    methods: {
        getData: function (page, size) {
            const self = this;
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";

            var rs = JSON.parse(atob(this.$route.query.data))

            ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/orders/list?page=" + page + "&size=" + size).then(function (response) {
                self.permissions = response.data.items
                self.pagination.total = response.data.total
                self.pagination.totalPages = response.data.totalPages
                self.pagination.page = page
                self.pagination.pageSize = size

                self.pagination.nextPages = self.getNextTwoPages()
                self.pagination.backPages = self.getPreviousTwoPages()

                if (0 == parseInt(response.data.totalPages)) {
                    document.getElementById("preloader").style.display = "none";
                    document.getElementById("status").style.display = "none";
                } else {
                    if (page > parseInt(response.data.totalPages)) {
                        self.pagination.page = parseInt(self.pagination.totalPages)
                        self.pagination.pageSize = parseInt(self.$route.query.size)
                        self.getData(self.pagination.page, self.pagination.pageSize)
                    } else {
                        document.getElementById("preloader").style.display = "none";
                        document.getElementById("status").style.display = "none";
                    }
                }
                if (self.type == "inovice"){
                    BredcrumpManagerEncode(self, { path: self.$route.path, title: 'orders.orders', page: self.pagination.page, size: self.pagination.pageSize })
                }else{
                    BredcrumpManagerEncode(self, { path: self.$route.path, title: 'orders.delivery', page: self.pagination.page, size: self.pagination.pageSize })
                }
            }).catch(
                function (error) {
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                if (error.response.data.code) {
                                    showNotificationFromServer(self, error.response.data.code)
                                } else {
                                    showNotificationWithText(self, "error.server.internalServer")
                                }
                            } else {
                                showNotificationWithText(self, "error.server.internalServer")
                            }
                        } else {
                            showNotificationWithText(self, "error.server.internalServer")
                        }
                    } else {
                        showNotificationWithText(self, "error.server.internalServer")
                    }
                }
            )
        },
        getPreviousTwoPages() {
            const currentPage = this.pagination.page;
            let previousTwoPages = [];
            for (let i = currentPage - 1; i >= 1 && i >= currentPage - 2; i--) {
                previousTwoPages.unshift(i);
            }
            return previousTwoPages;
        },
        getNextTwoPages() {
            const currentPage = this.pagination.page;
            const totalPages = this.pagination.totalPages;

            let nextTwoPages = [];
            for (let i = currentPage + 1; i <= totalPages && i <= currentPage + 2; i++) {
                nextTwoPages.push(i);
            }

            return nextTwoPages;
        },
        configureDataNextModule: function (id) {
            if (this.$route.query.data) {
                var rs = JSON.parse(atob(this.$route.query.data))
                rs.platformId = id
                return btoa(JSON.stringify(rs))
            }
        },
        configureLinkNextModule: function (url) {
            if (this.$route.path.includes("platform")) {
                return "/platform/" + url
            } else {
                return url
            }
        }
    },
    mounted() {
        if (this.$route.query.data) {
            var data = BredcrumpManagerDecode(this)
            if (data) {
                this.pagination.page = parseInt(data.page)
                this.pagination.pageSize = parseInt(data.size)
                this.getData(this.pagination.page, this.pagination.pageSize)
            } else {
                this.getData(this.pagination.page, this.pagination.pageSize)
            }
        } else {
            this.getData(this.pagination.page, this.pagination.pageSize)
        }
    }
};
</script>
<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th>{{ $t("orders.list.identification") }}</th>
                        <th>{{ $t("orders.list.client") }}</th>
                        <th>{{ $t("orders.list.date") }}</th>
                        <th>{{ $t("orders.list.statusOrder") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in permissions" :key="index">
                        <td>{{ $t("orders.list.id") }}<br>{{ item.id }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            {{ $t("orders.list.orderNumber") }}<br>{{ item.order_number }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;" v-if="type == 'invoice'">
                            <router-link v-if="type == 'invoice'"
                                :to="{ path: configureLinkNextModule('invoice/update'), query: { data: configureDataNextModule(null) } }">{{
                                    $t("orders.update") }}</router-link>
                        </td>
                        <td>
                            {{ $t("orders.list.customer") }}<br>{{ item.client.first_name }} {{ item.client.last_name }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            {{ $t("orders.list.municipality") }}<br>{{ item.client.municipality.title }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            {{ $t("orders.list.address") }}<br>{{ item.client.address }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            <p v-if="item.client.phone">{{ $t("orders.list.phone") }}<br>{{ item.client.phone }}</p>
                            <p v-else>{{ $t("orders.list.phone") }}<br>{{ $t("no.value") }}</p>
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            <p v-if="item.client.phone2">{{ $t("orders.list.phone2") }}<br>{{ item.client.phone2 }}</p>
                            <p v-else>{{ $t("orders.list.phone2") }}<br>{{ $t("no.value") }}</p>
                        </td>
                        <td>{{ $t("orders.list.orderCreatedDate") }}<br>{{ $moment(item.order_date,
                            "YYYY-MM-DD").format("DD/MM/YYYY") }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            {{ $t("orders.list.orderDeliveryDate") }}<br>{{ $moment(item.delivery_date,
                                "YYYY-MM-DD").format("DD/MM/YYYY") }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            {{ $t("orders.list.orderStatusDate") }}<br>{{
                                $moment(item.lastOrderStatusDate).format("DD/MM/YYYY") }}
                        </td>
                        <td>{{ $t("orders.list.orderTypes") }}<br>{{ $t('orderTypes.' + item.order_type.title) }}
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            {{ $t("orders.list.orderCreatedFrom") }}<br>{{ item.employee.user.first_name }} {{
                                item.employee.user.last_name }}<br><b>{{
        item.employee.detail.permission.title }}</b>
                            <hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">
                            {{ $t("orders.list.status") }}<br>{{ $t("orderDeliveryStatus." + item.orderStatus.name) }}
                        </td>
                        <td style="padding: 0px!important;">
                            <table class="table mb-0 mt-0">
                                <tr v-if="item.totalOrderAmount">
                                    <td>{{ $t("orders.list.orderAmountToPay") }} </td>
                                    <td>{{ parseFloat(item.totalOrderAmount).toFixed(2) }} ден.</td>
                                </tr>
                                <tr v-if="item.totalOrderAmountToEarn">
                                    <td>{{ $t("orders.list.earningsAmount") }}</td>
                                    <td>{{ parseFloat(item.totalOrderAmountToEarn).toFixed(2) }} ден.</td>
                                </tr>
                                <tr v-if="item.totalOrderEmployeeEarning">
                                    <td>{{ $t("orders.list.earningsAmountAgent") }}</td>
                                    <td>{{ parseFloat(item.totalOrderEmployeeEarning).toFixed(2) }} ден.</td>
                                </tr>
                                <tr v-if="item.totalOrderLeaderEarning">
                                    <td>{{ $t("orders.list.earningsAmountLeader") }}</td>
                                    <td>{{ parseFloat(item.totalOrderLeaderEarning).toFixed(2) }} ден.</td>
                                </tr>
                                <tr v-if="item.totalOrderOwnerEarning">
                                    <td>{{ $t("orders.list.earningsAmounOwner") }}</td>
                                    <td>{{ parseFloat(item.totalOrderOwnerEarning).toFixed(2) }} ден.</td>
                                </tr>
                                <tr v-if="item.totalOrderDevelopersEarning">
                                    <td>{{ $t("orders.list.earningsAmounDevelopers") }}</td>
                                    <td>{{ parseFloat(item.totalOrderDevelopersEarning).toFixed(2) }} ден.</td>
                                </tr>
                                <tr v-if="item.totalOrderCompanyEarning">
                                    <td>{{ $t("orders.list.earningsAmounCompany") }}</td>
                                    <td>{{ parseFloat(item.totalOrderCompanyEarning).toFixed(2) }} ден.</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-4" v-if="pagination.totalPages != 1 && permissions.length != 0">
            <div class="col-sm-6">
                <div>
                    <p class="mb-sm-0 mt-2">
                        {{ this.$t("pagination.page") }}
                        <span class="font-weight-bold">{{ pagination.page }}</span> {{ this.$t("pagination.from") }}
                        <span class="font-weight-bold">{{ pagination.totalPages }}</span>
                    </p>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="float-sm-right">
                    <ul class="pagination pagination-rounded mb-sm-0">
                        <li class="page-item" v-bind:class="(pagination.page == 1) ? 'disabled' : ''">
                            <a href="#" class="page-link"
                                @click="getData(parseInt(pagination.page) - 1, pagination.pageSize)">
                                <i class="mdi mdi-chevron-left"></i>
                            </a>
                        </li>
                        <li class="page-item" v-for="item in pagination.backPages" :key="item">
                            <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                        </li>
                        <li class="page-item active">
                            <a href="#" class="page-link">{{ pagination.page }}</a>
                        </li>
                        <li class="page-item" v-for="item in pagination.nextPages" :key="item">
                            <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                        </li>
                        <li class="page-item" v-bind:class="(pagination.page == pagination.totalPages) ? 'disabled' : ''">
                            <a href="#" class="page-link"
                                @click="getData(parseIn(pagination.page) + 1, pagination.pageSize)">
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>